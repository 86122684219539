import React, {useState} from 'react';
import RowDetails from './RowDetails'

const SearchResultsTable = ({ searchResults, term }) => {
    const [selectedRow, setSelectedRow] = useState(null);
    
    const handleRowClick = (index) => {
        if (selectedRow === index) {
            // If the clicked row is already selected, deselect it
            setSelectedRow(null);
        } else {
            // Otherwise, select the clicked row
            setSelectedRow(index);
        }
    };

    const handleClose = () => {
        setSelectedRow(null);
    };

    const handlePrev = () => {
        if (selectedRow > 0) {
            setSelectedRow(selectedRow - 1);
        }
    };

    const handleNext = () => {
        if (selectedRow < searchResults.length - 1) {
            setSelectedRow(selectedRow + 1);
        }
    };
  
    return (
        <div className='table-container'>
            {selectedRow != null ?
                (<RowDetails
                    data={searchResults[selectedRow]}
                    term={term}
                    onClose={handleClose}
                    onPrev={handlePrev}
                    onNext={handleNext}
                 />) :
                (<table className='table my-3'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults.map((result, index) => (
                            <tr key={result.id} onClick={() => handleRowClick(index)}>
                                <td>{result.name}</td>
                                <td>{result.date}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                )}
        </div>
    );
};

export default SearchResultsTable;
