import React, {useState} from "react";
import SearchResultsTable from './SearchResultsTable';
import { fetchSearchData } from "../services/searchApi";

export const SimpleSearchForm = () => {  
    const [allFields, setAllFields] = useState(""); 
    const [searchResults, setSearchResults] = useState([]);

    const SimpleSearch = async () => {
        try {
            let data = await fetchSearchData({allFields});           
            setSearchResults(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        SimpleSearch();
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="input-group">
                    <input type="text" name="all" className="form-control" placeholder="search in all data" 
                            value={allFields}
                            onChange={(e) => setAllFields(e.target.value)}/>
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="button" onClick={SimpleSearch}>Search</button>
                    </div>
                </div>
            </form>
            <SearchResultsTable searchResults={searchResults} term={allFields} />
        </>
    );
};

export const AdvancedSearchForm = () => {   
    const [name, setName] = useState(""); 
    const [pacientID, setPacientID] = useState(""); 
    const [protocol, setProtocol] = useState(""); 
    const [description, setDescription] = useState(""); 
    const [conclusion, setConclusion] = useState(""); 
    const [medic, setMedic] = useState(""); 
    const [startDate, setStartDate] = useState(""); 
    const [endDate, setEndDate] = useState(""); 
    const [searchResults, setSearchResults] = useState([]);

    const Search = async () => {
        try {
            let data = await fetchSearchData({name, pacientID, protocol, description, conclusion,medic, startDate, endDate});           
            setSearchResults(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        SimpleSearch();
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="form-group row mb-3">
                    <label className="col-3 col-form-label">Name</label>
                    <div className="col-9">
                        <input type="text" name="name" className="form-control" placeholder="Name" 
                            value={name}
                            onChange={(e) => setName(e.target.value)}/>
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <label className="col-3 col-form-label">PacientID</label>
                    <div className="col-9">
                        <input type="text" name="pacientId" className="form-control" placeholder="PacientID" 
                            value={pacientID}
                            onChange={(e) => setPacientID(e.target.value)} />
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <label className="col-3 col-form-label">Protocol</label>
                    <div className="col-9">
                        <input type="text" name="protocol" className="form-control" placeholder="Protocol" 
                            value={protocol}
                            onChange={(e) => setProtocol(e.target.value)} />
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <label className="col-3 col-form-label">Description</label>
                    <div className="col-9">
                        <input type="text" name="description" className="form-control" placeholder="Description" 
                            value={description}
                            onChange={(e) => setDescription(e.target.value)} />
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <label className="col-3 col-form-label">Conclusion</label>
                    <div className="col-9">
                        <input type="text" name="conclusion" className="form-control" placeholder="Conclusion" 
                            value={conclusion}
                            onChange={(e) => setConclusion(e.target.value)} />
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <label className="col-3 col-form-label">Medic</label>
                    <div className="col-9">
                        <input type="text" name="medic" className="form-control" placeholder="Medic" 
                            value={medic}
                            onChange={(e) => setMedic(e.target.value)} />
                    </div>
                </div>                
                <div className="form-group row mb-3">
                    <label className="col-3 col-form-label">Date:</label>
                    <div className="col-9">
                        <div className="row">
                            <div className="col-6">
                                <input type="date" name="startDate" className="form-control" placeholder="From Date" 
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                            <div className="col-6">
                                <input type="date" name="endDate" className="form-control" placeholder="To Date" 
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group row mb-3">
                    <div className="col-12 d-flex justify-content-end">
                        <button className="btn btn-primary" type="button" onClick={Search}>Search</button>
                    </div>
                </div>
            </form>
            <SearchResultsTable searchResults={searchResults} term={description} />
        </>
    );
}
