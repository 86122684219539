import React, {useState} from 'react';

const RowDetails = ({ data, term, onClose, onPrev, onNext }) => {
    const wordToUnderline = term;
    const description = data.description.replace(new RegExp(`${wordToUnderline}`, 'gi'), (match) => `<span class='fw-semibold text-primary'>${match}</span>`);
    const conclusion = data.conclusion.replace(new RegExp(`${wordToUnderline}`, 'gi'), (match) => `<span class='fw-semibold text-primary'>${match}</span>`);
 
    return (
        <>
            <div className='container-fluid position-absolute top-0 start-0 bg-white gx-5' style={{ width: '100vw', height: 'calc(100vh - 70px)', zIndex: 9990, overflow: 'auto'}}>
                <div className='row pb-3 mt-4'>
                    <div className='col-8 text-uppercase fw-bold'>{data.name}</div>
                    <div className='col-4 text-end'>{data.date}</div>
                </div>
                <div className='row pb-3 border-bottom'>
                    <div className='col-6 text-uppercase'>{data.protocol}</div>
                    <div className='col-6 text-end'>{data.medic}</div>
                </div>
                <div className='row py-3 border-bottom'>
                    <div className='col'>
                        <span className='fw-bold text-secondary'>Description:</span> <br></br>
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </div>
                <div className='row py-3'>
                    <div className='col'>
                        <span className='fw-bold text-secondary'>Conclusion:</span> <br></br>
                        <div dangerouslySetInnerHTML={{ __html: conclusion }} />
                    </div>
                </div>
            </div>
            <div className='container-fluid position-fixed bottom-0 start-0 bg-light gx-5' style={{ width: '100vw', height: 'auto', zIndex: 9999 }}>
                <div className='row my-3'>
                    <div className='col-5'>
                        <button onClick={onPrev} className='btn btn-outline-secondary w-100 d-flex justify-content-center'>
                            <i className="bi bi-chevron-left"></i>
                        </button>
                    </div>
                    <div className='col-2'>
                        <button onClick={onClose}
                            type="button" className="btn btn-outline-secondary w-100 d-flex justify-content-center" aria-label="Close">
                            <i className="bi bi-x-lg"></i>       
                        </button>                    
                    </div>
                    <div className='col-5'>
                        <button onClick={onNext} className='btn btn-outline-secondary w-100 d-flex justify-content-center' >
                            <i className="bi bi-chevron-right"></i>
                        </button>
                    </div>                
                </div>
            </div>
        </>
    );
};

export default RowDetails;