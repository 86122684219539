import React, { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph } from "./graph";
import Button from "react-bootstrap/Button";
import "./styles/App.css";
import { SimpleSearchForm, AdvancedSearchForm } from "./components/Form";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken).then(response => setGraphData(response));
        });
    }

    return (
        <>
            <h5 className="card-title">Welcome {accounts[0].name}</h5>
            {graphData ? 
                <ProfileData graphData={graphData} />
                :
                <Button variant="secondary" onClick={RequestProfileData}>Request Profile Information</Button>
            }
        </>
    );
};

/**
 * If a user is authenticated it can search.
 */
const MainContent = () => {   
    
    const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);
    
    return (
        <div className="App container pt-2">
            <AuthenticatedTemplate>                
                <div className="mt-2 mb-3 btn-group" role="group">
                    <input onClick={() => setIsAdvanceSearch(!isAdvanceSearch)} 
                        type="checkbox" className="btn-check" id="customSwitch1" autocomplete="off"></input>
                    <label className="btn btn-outline-primary" for="customSwitch1">Advanced search</label>
                </div>
                { !isAdvanceSearch &&  <SimpleSearchForm /> }
                { isAdvanceSearch &&  <AdvancedSearchForm /> }
                {/* <ProfileContent /> */}
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to be able to search.</h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
